import React from 'react'
import ReactDOM from 'react-dom/client'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { history } from './Config'
import App from './App'
import LoginPage from './components/LoginPage'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <Router history={history}>
      <Routes>
        <Route path='login' element={<LoginPage />} />
        <Route path='' element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
)
