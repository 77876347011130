import ApiService from './ApiService'
import { serverUrl } from '../Config'
import { HttpMethod } from '../types/HttpMethod'
import { UpdateProfile } from '../types/UpdateProfile'

const ProfileApiService = ApiService.generateControllerRequestHelper(`${serverUrl}/api/profile`)

const updateProfile = (updateProfileData: UpdateProfile) => {
  return ProfileApiService<void>('', {
    method: HttpMethod.PUT,
    body: JSON.stringify(updateProfileData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export { updateProfile }
