import React, { useRef, useState } from 'react'
import { Button, Input, InputRef, Popconfirm, Space, Table } from 'antd'
import { SecuredPhone } from '../../types/SecuredPhone'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType, ColumnType } from 'antd/es/table'
import Highlighter from 'react-highlight-words'

type DataIndex = keyof SecuredPhone

interface SecuredPhonesPageContentProps {
  securedPhones: SecuredPhone[]
  onDeleteSecuredPhone: (id: number) => void
  onSelectEditingPhone: (securedPhone: SecuredPhone) => void
}

export const SecuredPhonesPageContent: React.FC<SecuredPhonesPageContentProps> = props => {
  const { securedPhones, onDeleteSecuredPhone, onSelectEditingPhone } = props
  const [searchText, setSearchText] = useState<string>('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0] ?? '')
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<SecuredPhone> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Найти
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                handleReset(clearFilters)
                confirm()
              }
            }}
            size='small'
            style={{ width: 90 }}
          >
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return (record[dataIndex] || '')
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase())
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const columns: ColumnsType<SecuredPhone> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Hardware ID',
      dataIndex: 'hardwareId',
      key: 'hardwareId',
      width: '30%',
    },
    {
      title: 'Пин',
      dataIndex: 'pinCode',
      key: 'pinCode',
    },
    {
      title: 'Action',
      key: 'action',
      render: (idx: number, securedPhone: SecuredPhone) => (
        <Space size='middle'>
          <Button type='ghost' onClick={() => onSelectEditingPhone(securedPhone)}>
            <EditOutlined />
            Редактировать
          </Button>
          <Popconfirm
            title='Вы уверены что хотите удалить этот делефон?'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => onDeleteSecuredPhone(securedPhone.id)}
            okText='Да'
            cancelText='Нет'
          >
            <Button danger={true}>
              <DeleteOutlined />
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return <Table dataSource={securedPhones} columns={columns} style={{ maxWidth: '90%' }} />
}
