import React from 'react'
import { Button, Card, Col, Form, Input, notification, Row } from 'antd'
import { login } from '../../services/AuthService'
import { ResponseError } from '../../types/ResponseError'
import { useNavigate } from 'react-router'

const LoginPage = () => {
  const navigate = useNavigate()
  return (
    <div style={{ background: '#ececec' }}>
      <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
        <Col>
          <Card title='Авторизация' headStyle={{ textAlign: 'center' }}>
            <Form
              name='basic'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 16 }}
              onFinish={user => {
                login(user).then(() => {
                  navigate('/')
                })
              }}
              autoComplete='off'
            >
              <Form.Item
                label='Имя пользователя'
                name='username'
                rules={[{ required: true, message: 'Необходимо ввести имя пользователя!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='Пароль'
                name='password'
                rules={[{ required: true, message: 'Необходимо ввести имя пароль!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default LoginPage
