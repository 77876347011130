import * as React from 'react'
import { useEffect, useState } from 'react'

import { Col, Divider, Layout, Menu, notification, Row } from 'antd'
import { SecuredPhonesPageContent } from './components/SecuredPhonesPageContent'
import { Content, Footer } from 'antd/es/layout/layout'

import { UnknownPhonesPageContent } from './components/UnknownPhonesPageContent'
import { SecuredPhone } from './types/SecuredPhone'
import { UnknownPhone } from './types/UnknownPhone'
import { deleteSecuredPhoneById, getAllSecuredPhones, updateSecuredPhone } from './services/SecuredPhoneService'
import { approveUnknownPhoneById, deleteUnknownPhoneById, getAllUnknownPhones } from './services/UnknownPhoneService'
import { UpdateSecuredPhoneModal } from './components/UpdateSecuredPhoneModal'
import { AppstoreOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { logout } from './services/AuthService'
import { useNavigate } from 'react-router'

import 'antd/dist/antd.css'
import './App.css'
import EditProfileModal from './components/EditProfileModal'
import { updateProfile } from './services/ProfileService'
import { UpdateProfile } from './types/UpdateProfile'

const App: React.FC = () => {
  const [securedPhones, setSecuredPhones] = useState<SecuredPhone[]>([])
  const [unknownPhones, setUnknownPhones] = useState<UnknownPhone[]>([])
  const [editingSecuredPhone, setEditingSecuredPhone] = useState<SecuredPhone | null>(null)
  const [showEditSecuredPhoneModal, setShowEditSecuredPhoneModal] = useState<boolean>(false)
  const [showEditProfileModal, setShowEditProfileModall] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllUnknownPhones().then(setUnknownPhones)
    }, 10000)
    return () => clearInterval(intervalId)
  }, [useState])

  useEffect(() => {
    getAllSecuredPhones().then(setSecuredPhones)
    getAllUnknownPhones().then(setUnknownPhones)
  }, [])

  const handleShowEditProfileModal = () => {
    setShowEditProfileModall(true)
  }

  const handleCloseUpdateSecuredPhoneModal = () => {
    setEditingSecuredPhone(null)
    setShowEditSecuredPhoneModal(false)
  }

  const handleDeleteSecuredPhone = (deletingSecuredPhoneId: number) => {
    deleteSecuredPhoneById(deletingSecuredPhoneId).then(() => {
      const idx = securedPhones.findIndex(({ id }) => id === deletingSecuredPhoneId)
      if (idx != -1) {
        setSecuredPhones([...securedPhones.slice(0, idx), ...securedPhones.slice(idx + 1)])
        notification['success']({
          message: 'Успешно!',
          description: 'Телефон успешно удален!',
        })
      }
    })
  }

  const handleDeleteUnknownPhone = (deletingUnknownPhoneId: number) => {
    deleteUnknownPhoneById(deletingUnknownPhoneId).then(() => {
      const idx = unknownPhones.findIndex(({ id }) => id === deletingUnknownPhoneId)
      if (idx != -1) {
        notification['success']({
          message: 'Успешно!',
          description: 'Неизвестное устройство успешно удалено!',
        })
        setUnknownPhones([...unknownPhones.slice(0, idx), ...unknownPhones.slice(idx + 1)])
      }
    })
  }

  const handleUpdateSecuredPhone = (securedPhone: SecuredPhone) => {
    return updateSecuredPhone(securedPhone).then(updatedSecuredPhone => {
      const idx = securedPhones.findIndex(({ id }) => id === securedPhone.id)
      if (idx !== -1) {
        setSecuredPhones([...securedPhones.slice(0, idx), updatedSecuredPhone, ...securedPhones.slice(idx + 1)])
        notification['success']({
          message: 'Успешно!',
          description: 'Телефон успешно обновлен!',
        })
        setShowEditSecuredPhoneModal(false)
        setEditingSecuredPhone(null)
      }
    })
  }

  const handleSelectEditingPhone = (securedPhone: SecuredPhone) => {
    setShowEditSecuredPhoneModal(true)
    setEditingSecuredPhone(securedPhone)
  }

  const handleApproveUnknownPhone = (unknownPhoneId: number) => {
    approveUnknownPhoneById(unknownPhoneId).then(securedPhone => {
      const idx = unknownPhones.findIndex(({ id }) => id === unknownPhoneId)
      if (idx != -1) {
        notification['success']({
          message: 'Успешно!',
          description: 'Неизвестное устройство успешно заапрувлено!',
        })
        setUnknownPhones([...unknownPhones.slice(0, idx), ...unknownPhones.slice(idx + 1)])
        setSecuredPhones([...securedPhones, securedPhone])
        handleSelectEditingPhone(securedPhone)
      }
    })
  }

  const handleCloseEditProfileModal = () => {
    setShowEditProfileModall(false)
  }

  const handleSaveEditedProfile = (profile: UpdateProfile) => {
    updateProfile(profile).then(() => {
      notification['success']({
        message: 'Успешно!',
        description: 'Профиль обновлен!!',
      })
      setShowEditProfileModall(false)
      logout()
      navigate('/login')
    })
  }

  return (
    <div style={{ height: '100%' }}>
      <Layout className='layout' style={{ height: '100vh' }}>
        <Menu theme='dark' mode='horizontal' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Menu.SubMenu key='profile' title='Профиль' icon={<UserOutlined />}>
            <Menu.Item key='edit' icon={<AppstoreOutlined />} onClick={handleShowEditProfileModal}>
              Редактировать
            </Menu.Item>
            <Menu.Item
              key='logout'
              icon={<LogoutOutlined />}
              onClick={() => {
                logout()
                navigate('/login')
              }}
            >
              Выйти
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Content style={{ padding: '50px 50px', overflow: 'auto' }}>
          <div className='site-layout-content'>
            <Row>
              <Col flex={6}>
                <Divider orientation='left'>Доверительные телефоны</Divider>
                <SecuredPhonesPageContent
                  securedPhones={securedPhones}
                  onDeleteSecuredPhone={handleDeleteSecuredPhone}
                  onSelectEditingPhone={handleSelectEditingPhone}
                />
              </Col>
              <Col flex={3}>
                <Divider orientation='left'>Неизвестные телефоны</Divider>
                <UnknownPhonesPageContent
                  unknownPhones={unknownPhones}
                  onDeleteUnknownPhone={handleDeleteUnknownPhone}
                  onApproveUnknownPhone={handleApproveUnknownPhone}
                />
              </Col>
            </Row>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Ant Design ©2022 Created by Alexey Melnikov</Footer>
      </Layout>
      {editingSecuredPhone && (
        <UpdateSecuredPhoneModal
          securedPhone={editingSecuredPhone}
          isVisible={showEditSecuredPhoneModal}
          onSave={securedPhone => handleUpdateSecuredPhone(securedPhone)}
          onClose={handleCloseUpdateSecuredPhoneModal}
        />
      )}
      {showEditProfileModal && (
        <EditProfileModal
          onClose={handleCloseEditProfileModal}
          onSave={handleSaveEditedProfile}
          show={showEditProfileModal}
        />
      )}
    </div>
  )
}

export default App
