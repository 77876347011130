import ApiService from './ApiService'
import { serverUrl } from '../Config'
import { HttpMethod } from '../types/HttpMethod'
import { SecuredPhone } from '../types/SecuredPhone'

const SecuredPhoneApiService = ApiService.generateControllerRequestHelper(`${serverUrl}/api/secured`)

const getAllSecuredPhones = () => {
  return SecuredPhoneApiService<SecuredPhone[]>('', { method: HttpMethod.GET })
}

const updateSecuredPhone = (securedPhone: SecuredPhone) => {
  return SecuredPhoneApiService<SecuredPhone>('', {
    method: HttpMethod.PUT,
    body: JSON.stringify(securedPhone),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const deleteSecuredPhoneById = (id: number) => {
  return SecuredPhoneApiService<void>(`?id=${id}`, {
    method: HttpMethod.DELETE,
  })
}

export { getAllSecuredPhones, updateSecuredPhone, deleteSecuredPhoneById }
