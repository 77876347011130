import ApiService from './ApiService'
import { serverUrl } from '../Config'
import { LoginUser } from '../types/LoginUser'
import { HttpMethod } from '../types/HttpMethod'
import { setToken } from '../StatlessStorage'

const AuthApiService = ApiService.generateControllerRequestHelper(`${serverUrl}/api/auth`)

const login = (loginUser: LoginUser) => {
  return AuthApiService<string>('', {
    method: HttpMethod.POST,
    body: JSON.stringify(loginUser),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(setToken)
}

const logout = () => {
  setToken('')
}

export { login, logout }
