import React from 'react'
import { Button, Popconfirm, Space, Table } from 'antd'
import { UnknownPhone } from '../../types/UnknownPhone'
import { CheckCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

interface UnknownPhonesPageContentProps {
  unknownPhones: UnknownPhone[]
  onDeleteUnknownPhone: (id: number) => void
  onApproveUnknownPhone: (id: number) => void
}
export const UnknownPhonesPageContent: React.FC<UnknownPhonesPageContentProps> = props => {
  const { unknownPhones, onDeleteUnknownPhone, onApproveUnknownPhone } = props

  const columns: ColumnsType<UnknownPhone> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'Hardware ID',
      dataIndex: 'hardwareId',
      key: 'hardwareId',
      width: '65%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (idx: number, unknownPhone: UnknownPhone) => (
        <Space size='middle'>
          <Button type='primary' onClick={() => onApproveUnknownPhone(unknownPhone.id)}>
            <CheckCircleOutlined />
            Апрув
          </Button>
          <Popconfirm
            title='Вы уверены что хотите удалить этот делефон?'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => onDeleteUnknownPhone(unknownPhone.id)}
            okText='Да'
            cancelText='Нет'
          >
            <Button danger={true}>
              <DeleteOutlined />
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={unknownPhones} columns={columns} style={{ maxWidth: '90%' }} />
    </>
  )
}
