import { Response as AppResponse } from '../types/Response'
import { RequestFn } from '../types/RequestFn'
import { notification } from 'antd'
import { history } from '../Config'
import { getToken } from '../StatlessStorage'

const AUTH_ERROR_CODES = [401, 403]

class ApiService {
  static handleFunctions = <T>(response: Response): Promise<T> => {
    return ApiService.handleResponse<T>(response).then(handledResponse => {
      if (!handledResponse.success) {
        if (AUTH_ERROR_CODES.includes(handledResponse.error.code)) {
          history.push('/login')
          throw handledResponse.error
        }
        notification['error']({
          message: 'Ошибка!',
          description: handledResponse.error.text,
        })
        throw handledResponse.error
      }
      return handledResponse.data
    })
  }

  public static request = (<T>(url: string, init?: RequestInit) => {
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: getToken(),
      },
    }).then(ApiService.handleFunctions)
  }) as RequestFn

  public static generateControllerRequestHelper(controllerUrl: string) {
    return <T>(url: string, init: RequestInit = {}): Promise<T> => {
      return ApiService.request<T>(`${controllerUrl}/${url}`, init)
    }
  }

  private static handleResponse<T>(response: Response): Promise<AppResponse<T>> {
    return response.json()
  }
}

export default ApiService
