import React from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { UpdateProfile } from '../../types/UpdateProfile'

interface EditProfileModalProps {
  onClose: () => void
  onSave: (data: UpdateProfile) => void
  show: boolean
}
const EditProfileModal: React.FC<EditProfileModalProps> = props => {
  const { show, onClose, onSave } = props
  return (
    <Modal title='Редактирование профиля' visible={show} closeIcon footer={null} onCancel={onClose}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} onFinish={onSave} autoComplete='off'>
        <Form.Item
          label='Пароль'
          name='confirm'
          rules={[{ required: true, message: 'Введите старый пароль для подтверждения!' }]}
        >
          <Input type='password' autoComplete='off' />
        </Form.Item>
        <Form.Item label='Новое имя пользователя' name='username'>
          <Input autoComplete='off' />
        </Form.Item>
        <Form.Item label='Новый пароль' name='password'>
          <Input type='password' autoComplete='off' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditProfileModal
