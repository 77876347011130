import React from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { SecuredPhone } from '../../types/SecuredPhone'

type SecuredPhoneModalProps = {
  securedPhone: SecuredPhone
  isVisible: boolean
  onClose: () => void
  onSave: (securedPhone: SecuredPhone) => void
}
export const UpdateSecuredPhoneModal: React.FC<SecuredPhoneModalProps> = ({
  securedPhone,
  isVisible,
  onClose,
  onSave,
}) => {
  const { id, name, pinCode, hardwareId } = securedPhone
  return (
    <Modal title='Редактирование телефона' visible={isVisible} onCancel={onClose} footer={null}>
      <Form
        name='basic'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ id, name, pinCode, hardwareId }}
        onFinish={onSave}
        autoComplete='off'
      >
        <Form.Item label='id' name='id'>
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          label='Название телефона'
          name='name'
          rules={[{ required: true, message: 'Поле должно быть заполнено!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Идентификатор устройства' name='hardwareId'>
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          label='Пин-код телефона'
          name='pinCode'
          rules={[{ required: true, message: 'Поле должно быть заполнено!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 25, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
