import ApiService from './ApiService'
import { serverUrl } from '../Config'
import { HttpMethod } from '../types/HttpMethod'
import { UnknownPhone } from '../types/UnknownPhone'
import { SecuredPhone } from '../types/SecuredPhone'

const UnknownPhoneApiService = ApiService.generateControllerRequestHelper(`${serverUrl}/api/unknown`)

const getAllUnknownPhones = () => {
  return UnknownPhoneApiService<UnknownPhone[]>('', { method: HttpMethod.GET })
}

const deleteUnknownPhoneById = (id: number) => {
  return UnknownPhoneApiService<void>(`?id=${id}`, {
    method: HttpMethod.DELETE,
  })
}

const approveUnknownPhoneById = (id: number) => {
  return UnknownPhoneApiService<SecuredPhone>(`approve?id=${id}`, {
    method: HttpMethod.POST,
  })
}

export { getAllUnknownPhones, deleteUnknownPhoneById, approveUnknownPhoneById }
